// Import CSS
import styles from '../css/app.pcss';

window.HELP_IMPROVE_VIDEOJS = false;

// import "node_modules/@fancyapps/ui/dist/fancybox.css";
// import '../css/modules/fancybox.pcss';
/* ------------------------------------------------
4X:
Accessibility Library
------------------------------------------------*/
// import whatsock4x from './libs/4X/4X.Max.js';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
// import globalEvents from './modules/globals/events.js';
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';

import videojs from 'video.js';
import Flickity from 'flickity';

import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer";


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

// Get Cookies
function getCookie (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

let scrolled = false;

window.addEventListener('scroll', function() {
    let overlay = document.querySelectorAll('.video-overlay');
    let themap = document.getElementById('the-map');
    let relocators = document.getElementById('relocators');

    var scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;

    if(scrollPos > 67){
        overlay[0].classList.remove('opacity-0');
    }
    else{
        overlay[0].classList.add('opacity-0');
    }

    scrolled = true;

});

// Modal
if (modal_enabled) {
    let overlay = document.querySelectorAll('.modal');

    window.setTimeout(function(){

        overlay[0].classList.remove('hidden');
         window.setTimeout(function(){
            overlay[0].classList.remove('opacity-0');
         }, 500);
    }, 1500);

    let closeModal = document.querySelectorAll('.close-modal');

    for (var i = closeModal.length - 1; i >= 0; i--) {
        closeModal[i].addEventListener('click', function(e){
            // e.preventDefault();
            overlay[0].classList.add('opacity-0');
            overlay[0].classList.add('hidden');
        });
    };
};

// Video Players
var options = {
    autoplay: 'muted',
    controls: false,
    loop: true,
    muted: true,
};

var player1 = videojs('hero_video', options);
var player2 = videojs('video_strip', options);

// Image Carouselok
let elem = document.querySelector('.main-carousel');
let flkty = new Flickity( elem, {
    cellAlign: 'center',
    contain: true,
    prevNextButtons: true,
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    lazyLoad: 3,
    arrowShape: {
        x0: 10,
        x1: 60, y1: 50,
        x2: 60, y2: 40,
        x3: 20
    },
    on: {
        ready: function() {
            window.setTimeout(function(){

            console.log('Flickity ready');
                flkty.reloadCells();
            }, 100)
        }
    }
});

let previousBtn = document.getElementsByClassName('btn--previous');
let nextBtn = document.getElementsByClassName('btn--next');

previousBtn[0].addEventListener('click', function(e){
    e.preventDefault();
    flkty.previous();
});

nextBtn[0].addEventListener('click', function(e){
    e.preventDefault();
    flkty.next();
})

// Setup Map
let map;

const loader = new Loader({
  apiKey: "AIzaSyARFiT8eVxCJdfriBvAupHAfIaLz38Rhxs",
  version: "weekly"
});

loader.load().then(async () => {
    const parser = new DOMParser();

    const { Map, InfoWindow } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");


    map = new Map(document.getElementById("acf-map"), {
        mapId: '6f26e92aba3cbf15',
        center: {
            lat: 39.96043244920606,
            lng: -75.1549157498169
        },
        streetViewControl: false,
        mapTypeControl: false,
        panControl: false,
        zoomControl: false,
        scaleControl: false,
        zoom: 16,
    });

    const infoWindow = new google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
    });

    let firstMarker = null;
    let firstContent = null;

    for (var i = locations.length - 1; i >= 0; i--) {
        const pinSvgString = '<svg viewBox="0 0 32 40" width="32" height="40" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 1)"><path d="m30.4384347 15.221342c.0004272-6.9197015-4.6673112-12.96867239-11.3609833-14.72281681-6.6936721-1.75414441-13.7281433 1.22814419-17.12146292 7.25870393-3.39331961 6.03055978-2.29121808 13.59119478 2.6824462 18.40211288l10.57600002 11.4 10.586-11.4c2.9704456-2.8630825 4.6468554-6.812376 4.6430066-10.938" class="pin-shape" /><path d="m15.2194347 7.98634198 1.239 1.488 1.737-.856.526 1.86300002 1.934-.079-.277 1.916 1.8.718-1.033 1.638 1.35 1.392-1.61 1.076.67 1.817-1.908.329-.127 1.932-1.877-.476-.9 1.713-1.521-1.2-1.521 1.2-.9-1.713-1.877.476-.129-1.933-1.90800002-.329.67-1.817-1.61-1.076 1.348-1.387-1.033-1.638 1.80000002-.718-.27900002-1.92 1.93500002.075.526-1.86300002 1.737.856z" class="pin-star"/></g></svg>';
        const pinSvg = parser.parseFromString(pinSvgString,"image/svg+xml",).documentElement;

        const position = {
            lat: locations[i].lat,
            lng: locations[i].lng
        };

        const content = locations[i].content;

        const marker = new AdvancedMarkerElement({
            map,
            position: position,
            content: pinSvg,
            title: "Pin "+i,
        });

        if (i==0) {
            firstMarker = marker;
            firstContent = content;
        };

        google.maps.event.addListener(marker, 'click', function(ele) {

            let allPins = document.querySelectorAll('.yNHHyP-marker-view');
            for (var i = allPins.length - 1; i >= 0; i--) {
                allPins[i].classList.remove("active");
            };

            let closestBtn = ele.domEvent.srcElement.closest('[role="button"]');

            if (closestBtn) {
                closestBtn.classList.add("active");
            };

            map.panTo(marker.position);
            infoWindow.setContent( content );
            infoWindow.open(map, marker);
        });

        google.maps.event.addListener(infoWindow,'closeclick',function(){
            let allPins = document.querySelectorAll('.yNHHyP-marker-view');
            for (var i = allPins.length - 1; i >= 0; i--) {
                allPins[i].classList.remove("active");
            };
        });
    };

    console.log( firstMarker );
    infoWindow.setContent( firstContent );
    infoWindow.open(map, firstMarker);
});


/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
